import { type ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'
import type { OperationalStatus } from '@lib/utilities/location-hours-utilities'

export interface LocationHoursSummaryProps extends ComponentPropsWithoutRef<'div'> {
  accessible?: boolean
  operationalStatus: OperationalStatus
  nextOpenTime?: string
  locationExceptionText?: string
}

const LocationHoursSummary = ({
  accessible,
  operationalStatus,
  nextOpenTime,
  locationExceptionText,
  className,
  ...props
}: LocationHoursSummaryProps) => {
  const operationalStatusColor = clsx(
    accessible
      ? ''
      : operationalStatus === 'Closed'
        ? 'text-danger'
        : operationalStatus === 'Open' || operationalStatus === 'Open 24 hours'
          ? 'text-success'
          : '',
    'font-bold'
  )

  if (!operationalStatus || operationalStatus === 'Hidden') {
    return null
  }

  return (
    <div className={clsx('font-medium', className)} {...props}>
      <div>
        <span className={operationalStatusColor}>{operationalStatus}</span>
        {nextOpenTime && ` - ${nextOpenTime}`}
      </div>
      {locationExceptionText && <div className="font-semibold">({locationExceptionText})</div>}
    </div>
  )
}

export default LocationHoursSummary
