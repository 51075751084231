'use client'

import { createContext, useContext, type ReactNode } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { config } from '@fortawesome/fontawesome-svg-core'
import useCustomScroll from '@hooks/useCustomScroll'
import { type AppConfigType } from '@lib/services/aws-app-config-service'

// AWS App config (+featureflags) for global usage via useContext
export const AppConfig = createContext<AppConfigType | undefined>(undefined)

export const useAppConfig = () => {
  const ctx = useContext(AppConfig)
  if (ctx === undefined) {
    throw new Error('useAppConfig must have a higher order ClientWrapper provider')
  }
  return ctx
}

// Prevent font awesome from creating a style element; instead
// we add it via PostCSS in styles/main.css
config.autoAddCss = false

export interface ClientWrapperProps {
  readonly children: ReactNode
  readonly appConfig: AppConfigType
}

export default function ClientWrapper({ children, appConfig }: ClientWrapperProps) {
  useCustomScroll()

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY ?? ''}
      scriptProps={{
        async: true,
      }}>
      <AppConfig.Provider value={appConfig}>{children}</AppConfig.Provider>
    </GoogleReCaptchaProvider>
  )
}
